<template>
  <div class="col-12">
    <b-row v-if="loading">
      <b-col cols="12">
        <b-spinner></b-spinner>
      </b-col>
    </b-row>
    <div v-else>
      <b-row>
        <b-col cols="10">
          <b-form-group label="Filter" label-for="filter-input" label-cols-sm="1" label-size="sm">
            <b-input-group size="sm">
              <b-form-input id="filter-input" v-model="search" type="search" @keydown="handleKeydown"
                placeholder="Type to Search"></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-button type='button' id='btn_submit' variant='primary' to="/sftp/importers/new">
            <span>Create File Type</span>
          </b-button>
        </b-col>
      </b-row>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>BigQuery Dataset Name</th>
            <th>Priority</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in items" :key="i">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.bigquery_dataset_name }}</td>
            <td>{{ item.priority }}</td>
            <td>{{ item.created_at }}</td>
            <td>{{ item.updated_at }}</td>
            <td>
              <b-button v-b-tooltip.hover title="Edit importer configuration" size="sm" variant="light"
                class="client-entry-action mr-2" :to="'/sftp/importers/' + item.id">
                <feather type="edit-2"></feather>
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12">
      <div class="col-12 d-flex justify-content-center">
        <b-pagination :disabled="loading" @change="changePage" v-model="currentPage" :total-rows="total"
          :per-page="itemsPerPage" size="md">
        </b-pagination>
      </div>
    </div>
  </div>
</template>

<script>

import * as R from 'ramda';
import { debounce } from 'lodash';
import moment from 'moment/moment';
import { mapGetters } from 'vuex';

const isNullOrEmpty = R.either(R.isNil, R.isEmpty);

export default {
  name: 'SFTPGenericImporterList',
  data() {
    return {
      search: '',
      items: [],
      loading: false,
      itemsPerPage: 10,
      currentPage: 1,
      total: 0,
    };
  },
  async beforeMount() {
    if (!this.canAccessSftpFileImporters || !this.isSftpSettingsEnabled || !this.canAccessSftpFileTypes) {
      this.$router.push('/not-found');
      return;
    }

    await this.loadImporters();
  },
  computed: {
    ...mapGetters({
      canAccessSftpFileImporters: 'User/canAccessSftpFileImporters',
      isSftpSettingsEnabled: 'Core/isSftpSettingsEnabled',
      canAccessSftpFileTypes: 'User/canAccessSftpFileTypes',
    }),
  },
  created() {
    this.searchEvent = debounce(this.searchEvent, 350, {
      leading: false,
      trailing: true,
    });
  },
  methods: {
    async loadImporters(search = '', resetPagination) {
      if (resetPagination) {
        this.currentPage = 1;
      }
      const offset = (this.currentPage - 1) * this.itemsPerPage;
      const limit = this.itemsPerPage;
      let res;
      if (search !== '') {
        res = await this.$store.dispatch('Sftp/GenericImporter/searchGenericImporters', { name: search, offset, limit })
          .then(response => response.data);
      } else {
        res = await this.$store.dispatch('Sftp/GenericImporter/getGenericImporters', { offset, limit }).then(response => response.data);
      }
      this.items = [];
      if (!isNullOrEmpty(res.data)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of res.data) {
          this.items.push(
            {
              id: item.id,
              name: item.name,
              priority: item.priority,
              bigquery_dataset_name: item.bigquery_dataset_name,
              created_at: moment(item.created_at).format('YYYY-MM-DD HH:mm'),
              updated_at: moment(item.updated_at).format('YYYY-MM-DD HH:mm'),
            },
          );
        }
      }
      this.total = res.count;
    },
    handleKeydown() {
      this.searchEvent();
    },
    searchEvent() {
      if (this.search.length === 0) {
        this.loadImporters();
        return;
      }

      if (!this.search || this.search.length < 3) {
        return;
      }

      this.loadImporters(this.search, true);
    },
    changePage(page) {
      this.currentPage = page;
      this.loadImporters(this.search);
    },
  },
};
</script>

<style>
.client-entry-action {
  min-width: initial;
  background-color: transparent;
  border: none;
}
</style>
